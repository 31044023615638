import Image from "next/image";
import React from "react";
import styles from "./FileFeed.module.css";
import DownloadIcon from "../../assets/download_icon.svg";
import FileCard from "./FileCard";
import BaseButton from "../buttons/baseButton";
import { conditionalRender } from "../../utils/helpers";
import { ProposalFileProps } from "../proposalCard/ProposalCard.entities";
import { FileType } from "../createProposalInputFiles/createProposalInputFiles.view";

type Props = {
  files: ProposalFileProps[];
};

const DOWNLOAD_BUTTON_HEIGHT = 12;
const DOWNLOAD_BUTTON_WIDTH = 13;

const FileFeedView = ({ files }: Props) => {
  const renderDownloadAllButton = () => (
    <BaseButton className={styles.downloadAllButton}>
      <Image
        className={styles.downloadButton}
        src={DownloadIcon}
        height={DOWNLOAD_BUTTON_HEIGHT}
        width={DOWNLOAD_BUTTON_WIDTH}
      />
      <div className={styles.downloadAllText}>Download all</div>
    </BaseButton>
  );
  return (
    <>
      <div className={styles.row}>
        <div className={styles.sectionTitle}>Files</div>
        {/* TODO: re-add once download file ability is added */}
        {/* {renderDownloadAllButton()} */}
      </div>
      {conditionalRender(
        files.length !== 0,
        <div className={styles.fileListContainer}>
          {files?.map((file) => {
            if (file.type === FileType.Thumbnail) return;
            return (
              <FileCard key={file.id} fileId={file.id} fileName={file.name} />
            );
          })}
        </div>
      )}
    </>
  );
};
export { FileFeedView };
