import { useState, useRef } from "react";
import { createPortal } from "react-dom";
import { IconButton } from "../iconButton";
import { ShareIcon } from "../icons/shareIcon";
import styles from "./ShareLinkButton.module.css";
import { colour } from "../../global/theme";

type Props = {
  onShareClick: () => void;
};

const ShareLinkButton = ({ onShareClick }: Props) => {
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleShareClick = () => {
    onShareClick();
    setShowCopyConfirmation(true);
    setTimeout(() => setShowCopyConfirmation(false), 2000);
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <IconButton onClick={handleShareClick}>
        <ShareIcon size={30} />
      </IconButton>
      {showCopyConfirmation &&
        createPortal(
          <div
            className={styles.copyConfirmation}
            style={{
              backgroundColor: colour.interactive,
            }}
          >
            Copied to clipboard
          </div>,
          containerRef.current ?? document.body
        )}
    </div>
  );
};

export { ShareLinkButton };
