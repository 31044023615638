import React from "react";
import styles from "./Footer.module.css";
import { JascroLink } from "../jascroLink/jascroLink";

const Footer = () => {
  return (
    <div className={styles.bottomBar}>
      <a className={styles.headerFooter}>Blockposal.io</a>
      <div className={styles.jascroLinkContainer}>
        <JascroLink theme="light" />
      </div>
    </div>
  );
};

export default Footer;
